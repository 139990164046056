* {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

@font-face {
    font-family: 'gillSans';
    font-style: normal;
    font-weight: normal;
    src: url('/storage/fonts/GillSans.woff') format("woff");
}

body::-webkit-scrollbar {
    width: 5px;
    height: 7px;
}
body::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}
body::-webkit-scrollbar-thumb {
    background: #525965;
    border: 0px none #ffffff;
    border-radius: 0px;
}
body::-webkit-scrollbar-thumb:hover {
    background: #525965;
}
body::-webkit-scrollbar-thumb:active {
    background: #525965;
}
body::-webkit-scrollbar-track {
    background: transparent;
    border: 0px none #ffffff;
    border-radius: 50px;
}
body::-webkit-scrollbar-track:hover {
    background: transparent;
}
body::-webkit-scrollbar-track:active {
    background: transparent;
}
body::-webkit-scrollbar-corner {
    background: transparent;
}

.landing-home {
    min-height: 100vh;
    height: 100vh;
    background: url('/storage/images/assets/landing_background.webp') center center;
    background-size: cover;
    padding: 3rem 0;
}

.color-teal {
    color: $teal;
}

.color-orange {
    color: #f18425;
}

.bg-teal {
    background-color: $teal;
}

.bg-orange {
    background-color: #f18425;
}

.btn-teal {
    background: $teal;
    color: white;
}

.btn-teal:hover {
    color: #FFF;
    box-shadow: 0 4px 10px rgb(31 186 162 / 50%);
    transition: .3s ease;
}

.btn-orange {
    background: #f18425;
    color: white;
}

.btn-orange:hover {
    color: #FFF;
    box-shadow: 0 4px 10px rgb(241 132 37 / 50%);
    transition: .3s ease;
}

.btn-white {
    background: #FFF;
}

.btn-white:hover {
    background: transparent;
    border: 1px solid #FFF;
    color: #FFF;
    transition: .3s ease;
}

.btn-outline-orange {
    color: #f18425;
    border: 1px solid #f18425;
    transition: .3s ease;
}

.btn-outline-orange:hover {
    background: #f18425;
    border: 1px solid #f18425;
    color: #FFF;
}

.btn-outline-dark-green {
    color: $darkGreen;
    border: 1px solid $darkGreen;
    transition: .3s ease;
}

.btn-outline-dark-green:hover {
    background: $darkGreen;
    border: 1px solid $darkGreen;
    color: #FFF;
}

.btn-outline-teal {
    color: $teal;
    border: 1px solid $teal;
    transition: .3s ease;
}

.btn-outline-teal:hover {
    background: $teal;
    border: 1px solid $teal;
    color: #FFF;
}

.btn-outline-light-green {
    color: $lightGreen;
    border: 1px solid $lightGreen;
    transition: .3s ease;
}

.btn-outline-light-green:hover {
    background: $lightGreen;
    border: 1px solid $lightGreen;
    color: #FFF;
}

.btn-outline-light-orange {
    color: $lightOrange;
    border: 1px solid $lightOrange;
    transition: .3s ease;
}

.btn-outline-light-orange:hover {
    background: $lightOrange;
    border: 1px solid $lightOrange;
    color: #FFF;
}

.btn-outline-dark-orange {
    color: $darkOrange;
    border: 1px solid $darkOrange;
    transition: .3s ease;
}

.btn-outline-dark-orange:hover {
    background: $darkOrange;
    border: 1px solid $darkOrange;
    color: #FFF;
}

.border-teal {
    border: 2px solid $teal;
}

.rounded-20 {
    border-radius: 20px;
}

.rounded-pill {
    border-radius: 12px !important;
}

.section-line {
    background-color: #f18425;
    width: 80px;
    height: 3px;
    margin-bottom: 1rem;
}

.img-shadow {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    position: absolute;
    bottom: 0;
}

.navbar-nav .nav-link {
    color: #6D6D6D !important;
    padding: 0.5rem 0.75rem !important;
    transition: 0.1s ease-in-out;
    transition: .5s ease;
    font-size: 1rem;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}

.navbar-nav .nav-link:hover,
.nav-link.active {
    color: $darkGreen !important;
}

.nav-link.active {
    color: $darkGreen !important;
    font-weight: bold;
}

.dropdown-menu {
    margin: 0.52em 0;
    transition: .5s ease;
}

.dropdown-item:hover {
    color: $darkGreen;
    background-color: transparent !important;
}

.dropdown-item.active,
.dropdown-item:active {
    color: $darkGreen;
    background-color: transparent !important;
}

.dashboard {
    padding: 7rem 0;
}

.color-dark-green {
    color: $darkGreen;
}

.text-loyalty {
    font-size: 84px;
    font-weight: bold;
}

.section-title-sm {
    font-family: 'gillSans';
    text-transform: uppercase;
    color: $darkGreen;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 4px;
    border-left: 5px solid $orange;
    padding-left: .5rem;
}

//Datatables
.page-item.active .page-link {
    color: #fff;
    background-color: $teal;
    border-color: $teal;
}

.page-link {
    color: $teal;
}

.page-link:hover {
    color: $teal;
}

.page-link:focus {
    box-shadow: none;
}

.dataTables_length {
    text-align: left !important;
}

.dataTables_filter {
    @include media-breakpoint-down(sm) {
        text-align: left !important;
    }
}

.dataTables_info {
    padding-top: 0 !important;
    text-align: left !important;
}

table.dataTable.dtr-inline.collapsed.table-sm>tbody>tr>td:first-child::before,
table.dataTable.dtr-inline.collapsed.table-sm>tbody>tr>th:first-child::before {
    top: 50% !important;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    @include media-breakpoint-down(sm) {
        justify-content: loadert !important;
    }
}