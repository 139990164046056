// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #00ACDC;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f18425;
$yellow: #ffed4a;
$green: #38c172;
$teal: #1fbaa2;
$cyan: #6cb2eb;
$darkGreen: #12806F;
$lightGreen: #85e0d2;
$lightOrange: #ffb87a;
$darkOrange: #e06900;